import React, { useEffect, useState } from 'react';
import { Container, Grid,CircularProgress, List, ListItem, ListItemText, ListItemSecondaryAction, Button, Typography } from '@material-ui/core';
import { getSessionList } from '../api';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';


const SessionList = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { eventId } = useSelector((state) => state.event);


  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);
      const result = await getSessionList(eventId);
      setSessions(result);
      setLoading(false);
    };

    fetchSessions();
  }, [eventId]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
        <Typography>Available Sessions</Typography>
    <List style={{ maxHeight: '500px', overflow: 'auto' }}>
    {sessions.map((session) => (
      <ListItem key={session.id}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={7} sm={8}>
            <ListItemText
              primary={session.attributes.name}
              secondary={(
                <>
                  <Typography variant="body2" color="textPrimary">
                    Presenter: {session.attributes.presenter}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Starts on: {session.attributes.starts_on}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Ends on: {session.attributes.ends_on}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    Description: {session.attributes.description}
                  </Typography>
                </>
              )}
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <ListItemSecondaryAction>
            <Button variant="contained" color="primary">
                <Link to={`/SessionDetail/${session.id}`} style={{ color: 'white', textDecoration: 'none' }}>
                  View Live Session
                </Link>
              </Button>
            </ListItemSecondaryAction>
          </Grid>
        </Grid>
      </ListItem>
    ))}
  </List>
  </Container>
  );
};

export default SessionList;
