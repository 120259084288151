import {React, useState, useEffect} from "react";
import { Button, Card, CardContent, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { setEventDetails } from '../eventSlice';
import { useHistory } from 'react-router-dom';
import { validateEventCode } from "../api";

const useStyles = makeStyles({
  card: {
    maxWidth: 500,
    padding: '20px',
    textAlign: 'center',
    marginTop: '20px',
  },
  title: {
    marginBottom: '10px',
  },
  textField: {
    marginTop: '20px',
  },
  submitButton: {
    marginTop: '20px',
  },
});

function EventCodeForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [apiError, setApiError] = useState(null);  // New state for API error

  const handleFormSubmit = async (data) => {
    console.log(data);
    const result = await validateEventCode(data.eventCode);
    if (result.data.length < 1) {
        setApiError("Invalid Event Code");  // Set the API error
        return;
      }
      console.log(result);
      
      const eventDetails = {
        eventId: result.data[0].id,
        eventCode: data.eventCode,
        eventName: result.data[0].attributes.name,
      };
    dispatch(setEventDetails(eventDetails));
    history.push('/UserDetail');
    // Add API call for event code validation here
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h4" className={classes.title}>
          Let's find your event!
        </Typography>
        {apiError && (
          <Typography color="error">
            {apiError} {/* Display API error message when it occurs */}
          </Typography>
        )}
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="eventCode"
            label="Event Code"
            name="eventCode"
            className={classes.textField}
            autoComplete="off"
            {...register("eventCode", { required: true, minLength: 6 })}
            error={errors.eventCode ? true : false}
            helperText={errors.eventCode && "Event Code must be at least 6 characters."}
          />
          <br></br><br></br>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}
          >
            Submit
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

export default EventCodeForm;
