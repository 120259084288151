import axios from 'axios';

const api = axios.create({
  baseURL: 'https://neweraapi.cheertechnologies.com/api', // replace with your API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export const validateEventCode = async (eventCode) => {
  const result = await api.get('/events?filters[event_code][$containsi]=' + eventCode);
  return result.data;
};

export const getSessionList = async (eventId) => {
  const result = await api.get(`/event-sessions?filters[event]=${eventId}`);
  return result.data.data;
};

export const createEventUser = async (submission) => {
    const result = await api.post('/event-users', {data: submission});
    return result.data.data;
}

export const postSessionView = async (sessionId, userId) => {
    const result = await api.post('/event-session-views', {
        data: {
        event_session: sessionId,
        event_user: userId,
        }
    });
    return result.data.data;
};

export const getSessionDetails = async (sessionId) => {
  const result = await api.get(`/event-sessions/${sessionId}?populate=event`);
  return result.data.data;
};

export const submitQuestion = (question, userName, userEmail, userPhoneNumber) => {
  return api.post('/submitQuestion', { question, userName, userEmail, userPhoneNumber });
};

// Add more API methods as needed
