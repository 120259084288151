import { createSlice } from '@reduxjs/toolkit';

const eventSlice = createSlice({
  name: 'event',
  initialState: {
    eventId: '',
    eventCode: '',
    eventName: '',
    userName: '',
    userEmail: '',
    userPhoneNumber: '',
    userId: 0,
    eventSessions: [],
  },
  reducers: {
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
    setEventName: (state, action) => {
        state.eventName = action.payload;
    },
    setEventCode: (state, action) => {
        state.eventCode = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userName = action.payload.userName;
      state.userEmail = action.payload.userEmail;
      state.userPhoneNumber = action.payload.userPhoneNumber;
      state.userId = action.payload.userId;
    },
    setEventDetails: (state, action) => {
        state.eventId = action.payload.eventId;
        state.eventCode = action.payload.eventCode;
        state.eventName = action.payload.eventName;
        state.userId = 0;
    },
    setUserId: (state, action) => {
        state.userId = action.payload.userId;
    },
    setEventSessions: (state, action) => {
        state.eventSessions = action.payload;
      },
      resetState: (state) => {
        state.eventId = '';
        state.userId = 0;
        state.eventCode = '';
        state.eventName = '';
        state.userName = '';
        state.userEmail = '';
        state.userPhoneNumber = '';
        state.eventSessions = [];
      },
  },
});

export const { setUserId, setEventId, setUserDetails, setEventSessions, setEventCode, resetState, setEventDetails} = eventSlice.actions;

export default eventSlice.reducer;