import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box, IconButton } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { resetState } from '../eventSlice';
import { useHistory } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';

const LoginDetailDisplay = () => {
  const history = useHistory();
  const location = useLocation();
  const { eventCode, eventId, eventName, userName, userEmail, userPhoneNumber} = useSelector((state) => state.event);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(resetState());
    history.push('/');
  };


  const hasContent = eventCode || eventId || eventName || userName || userEmail || userPhoneNumber;

  // If there's no content to display, render nothing
  if (!hasContent) {
    return null;
  }
  return (
    <Box my={2} display="flex" alignItems="center" justifyContent="space-between">
    <Box>
    {eventCode && (
      <IconButton color="primary" onClick={handleLogout} size="small">
        Logout
        <ExitToAppIcon />
      </IconButton>
    )}
      {eventCode && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Event Code: {eventCode}
        </Typography>
      )}
      {eventName && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Event Name: {eventName}
        </Typography>
      )}
      {userName && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          User Name: {userName}
        </Typography>
      )}
      {userEmail && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Email: {userEmail}
        </Typography>
      )}
      {userPhoneNumber && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Phone Number: {userPhoneNumber}
        </Typography>
      )}
      {eventId && userName && userEmail && userPhoneNumber && eventName && location.pathname !== "/" &&(<Link to={`/`}>
              <IconButton color="primary" size="small">
                Sessions List
            </IconButton>
            </Link>
      )}
    </Box>
  </Box>

  );
};

export default LoginDetailDisplay;
