import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStorage';
import eventReducer from './eventSlice';

const persistedState = loadState();

const store = configureStore({
  reducer: {
    event: eventReducer,
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveState({
    event: store.getState().event
  });
});

export default store;