import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'; // new import
import { CircularProgress, Typography } from '@material-ui/core';
import { getSessionDetails, postSessionView } from '../api';

function SessionDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);
  const [error, setError] = useState(null); // new state for error message

  const videoPlayerRef = useRef(null);

  // get the eventId from Redux state
  const eventId = useSelector(state => state.event.eventId);
  const userId = useSelector(state => state.event.userId);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getSessionDetails(id);
      console.log(result.attributes);
      // Check if session's event id matches the one from state
      if (result.attributes.event.data.id !== eventId) {
        setError("You do not have permission to access this resource. Please go back to the homepage.");
        setLoading(false);
        return;
      }

        // Post a view to the session
        try{
            await postSessionView(result.id, userId);
        } catch(err){
            setError(null); // clear error message if previously set
        }
        
      setSession(result);
      setLoading(false);
    }
    fetchData();
  }, [id, eventId, userId]); // add eventId to the dependencies

  useEffect(() => {
    let playerScript;
  
    if (session && session.attributes.embed) {
      const scriptSrcMatch = session.attributes.embed.match(/src=['"]([^'"]*)['"]/i);
      const scriptSrc = scriptSrcMatch && scriptSrcMatch[1];
      
      if (scriptSrc) {
        playerScript = document.createElement('script');
        playerScript.src = scriptSrc;
        playerScript.async = true;
        videoPlayerRef.current.appendChild(playerScript);
      }
    }
  
    // The cleanup function
    return () => {
      if (videoPlayerRef.current && playerScript) {
        videoPlayerRef.current.removeChild(playerScript);
      }
    }
  }, [session]);

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      <Typography variant="h4">{session.attributes.name}</Typography>
      <Typography variant="h6">Begins: {session.attributes.starts_on}</Typography>
      <Typography variant="h6">Ends: {session.attributes.ends_on}</Typography>
      <Typography variant="body1">Description: {session.attributes.description}</Typography>
      <Typography variant="body1">Presenter: {session.attributes.presenter}</Typography>
      <div id='wowza_player' ref={videoPlayerRef} />
    </div>
  );
}

export default SessionDetail;
