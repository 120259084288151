import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { setUserDetails } from '../eventSlice';
import { useHistory } from 'react-router-dom';
import { createEventUser } from '../api';


const UserDetailForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { eventId } = useSelector((state) => state.event);
  
  const [values, setValues] = useState({
    userName: '',
    userEmail: '',
    userPhoneNumber: '',
    userId: null,
  });
  
  const [errors, setErrors] = useState({});
  
  const validate = () => {
    let tempErrors = {};
    tempErrors.userName = values.userName ? "" : "This field is required.";
    tempErrors.userEmail = values.userEmail ? "" : "This field is required.";
    tempErrors.userPhoneNumber = values.userPhoneNumber ? "" : "This field is required.";
    setErrors({
      ...tempErrors
    });
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      const submission = {
          name: values.userName,
          email: values.userEmail,
          phone_number: values.userPhoneNumber,
          event: eventId
      };
      const user = await createEventUser(submission);
      dispatch(setUserDetails({...values, userId: user.id}));
      history.push('/');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  
  return (
    <Grid container justifyContent="center">
      <Card>
        <CardContent>
          <Typography variant="h4" component="h2">
            User Details
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              name="userName"
              label="Name"
              fullWidth
              value={values.userName}
              onChange={handleInputChange}
              {...(errors.userName && { error: true, helperText: errors.userName })}
            />
            <TextField
              name="userEmail"
              label="Email"
              fullWidth
              value={values.userEmail}
              onChange={handleInputChange}
              {...(errors.userEmail && { error: true, helperText: errors.userEmail })}
            />
            <TextField
              name="userPhoneNumber"
              label="Phone Number"
              fullWidth
              value={values.userPhoneNumber}
              onChange={handleInputChange}
              {...(errors.userPhoneNumber && { error: true, helperText: errors.userPhoneNumber })}
            />
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UserDetailForm;
