import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  card: {
    maxWidth: 500,
    padding: '20px',
    textAlign: 'center',
    marginTop: '20px',
  },
  title: {
    marginBottom: '10px',
  },
  actionButton: {
    marginTop: '20px',
  },
});

const GetStarted = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/EventCode');
  };
  return (
    <Card className={classes.card}>
    <CardContent>
      <Typography variant="h4" className={classes.title}>
        Live Education Sessions
      </Typography>
      <br></br>
      <Typography>
        Have your event code ready to access the live education streams. Once you are ready, click the button below to get started.
      </Typography>
      <br></br>
      <Button variant="contained" color="primary" className={classes.actionButton} onClick={handleButtonClick}>
        Get Started
      </Button>
    </CardContent>
  </Card>
  );
};

export default GetStarted;
