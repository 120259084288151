import React from "react";
import { Container, Typography, Box, Link, Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import GetStarted from './components/GetStarted.js';
import EventCodeForm from './components/EventCodeForm.js';
import LoginDetailDisplay from "./components/LoginDetailDisplay.js";
import UserDetailForm from "./components/UserDetailForm.js";
import { useSelector } from 'react-redux';
import SessionList from './components/SessionList.js';
import SessionDetail from './components/SessionDetail.js';

const RootRoute = () => {
  const { eventId, eventCode, userName, userEmail, userPhoneNumber } = useSelector((state) => state.event);
  if (!eventCode && !eventId && !userName && !userEmail && !userPhoneNumber) {
    return <GetStarted />;
  } else if (eventCode && !userName && !userEmail && !userPhoneNumber) {
    return <UserDetailForm />;
  } else {
    return <SessionList />;
  }

  // const userHasDetails = eventCode && eventId && userName && userEmail && userPhoneNumber;

  // return userHasDetails ? <GetStarted /> : <EventCodeForm />;
};


const useStyles = makeStyles({
  logo: {
    height: '100px',
  },
  card: {
    maxWidth: 500,
    padding: '20px',
    textAlign: 'center',
    marginTop: '20px',
  },
  title: {
    marginBottom: '10px',
  },
  actionButton: {
    marginTop: '20px',
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  contentContainer: {
    flex: '1 0 auto',
  },
  splashContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e6ecf0',
    paddingBottom: '50px', // Adjust to your preference
  },
  footer: {
    flexShrink: 0,
    textAlign: 'center',
    padding: '10px 0',
    backgroundColor: '#f5f8fa',
  },
  partnerLogo: {
    height: '80px',
  },
  partnerText: {
    textAlign: 'center',
    marginBottom: '10px',
  },
});


export default function Nav() {
  const classes = useStyles();
  return (
    <div className={classes.appContainer}>
      <div className={classes.contentContainer}>
        <Container className={classes.splashContainer}>
            <Router>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item container justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <img src="./summerspiritfest.png" alt="Logo" className={classes.logo} />
            </Grid>
            <Grid item>
            <img src="./newera.png" alt="Logo" className={classes.logo} />
              </Grid>
            <Grid item>
              <Typography className={classes.partnerText}>Technology Partner</Typography>
              <Link href="https://www.cheerreplay.com/" target="_blank" rel="noopener noreferrer">
                <img src="https://static.wixstatic.com/media/4973e2_246df04d587b472fa0896076f5c7759d~mv2.png/v1/fill/w_599,h_503,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4973e2_246df04d587b472fa0896076f5c7759d~mv2.png" alt="Partner Logo" className={classes.partnerLogo} />
              </Link>
            </Grid>
          </Grid>
         
          <Grid item><LoginDetailDisplay /></Grid>
          <br></br>
          <Grid item>
                      <Switch>
                        <Route path="/" exact>
                          <RootRoute />
                          </Route>
                        <Route path="/EventCode" exact component={EventCodeForm} />
                        <Route path="/UserDetail" exact component={UserDetailForm} />
                        <Route path="/SessionDetail/:id" component={SessionDetail} />
                      </Switch>
                  
          </Grid>
        </Grid>
            </Router>
        </Container>
      </div>
      <Box className={classes.footer}>
        <Typography variant="body2">Copyright © 2023 Cheer Technologies, LLC</Typography>
      </Box>
    </div>
  );
}

// export default function Nav() {
//   const classes = useStyles();
//   return (
//     <>
//       <Container className={classes.splashContainer}>
//         <Provider store={store}>
//         <Router>
//         <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
//           <Grid item container justifyContent="center" alignItems="center" spacing={2}>
//             <Grid item>
//               <img src="https://www.iasfworlds.com/wp-content/uploads/2020/05/IASF-Logo-copy.png" alt="Logo" className={classes.logo} />
//             </Grid>
//             <Grid item>
//               <Typography className={classes.partnerText}>Technology Partner</Typography>
//               <Link href="https://www.cheerreplay.com/" target="_blank" rel="noopener noreferrer">
//                 <img src="https://static.wixstatic.com/media/4973e2_246df04d587b472fa0896076f5c7759d~mv2.png/v1/fill/w_599,h_503,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4973e2_246df04d587b472fa0896076f5c7759d~mv2.png" alt="Partner Logo" className={classes.partnerLogo} />
//               </Link>
//             </Grid>
//           </Grid>
         
//           <Grid item><LoginDetailDisplay /></Grid>
//           <Grid item>
//                       <Switch>
//                         <Route path="/" exact>
//                           <RootRoute />
//                           </Route>
//                         <Route path="/EventCode" exact component={EventCodeForm} />
//                         <Route path="/UserDetail" exact component={UserDetailForm} />
//                         <Route path="/SessionDetail/:id" component={SessionDetail} />
//                       </Switch>
                  
//           </Grid>
//         </Grid>
//         </Router>
//         </Provider>
//       </Container>
//       <Box className={classes.footer}>
//         <Typography variant="body2">Copyright © 2023 Cheer Technologies, LLC</Typography>
//       </Box>
//     </>
//   );
// }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
